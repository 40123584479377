// scss
import './src/styles/index.scss';

import { ActionContextProvider, createConfig } from './src/cra';

import React from 'react';
export const wrapRootElement = ({ element }) => {
  createConfig({
    website: 'Life Compared',
  });
  return <ActionContextProvider>{element}</ActionContextProvider>;
};
